import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';
import {device} from '../../theme';
import SVG from 'react-inlinesvg';

export const AccentSection = styled.section `
    
`;

export const AccentWrapper = styled(BackgroundImage)`
  background-attachment: fixed;
  z-index: 1 !important;
  .overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17,17,17,0.55);
    z-index: 1;
  }
`;

const ServiceCard = styled.div`
  padding: 60px 5px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
  flex-basis: 20%;

  .iconContainer {
    margin-bottom: 1em;
    width: 150px;
    height: 150px;
    vertical-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  span {
    color: #ececec;
    font-weight: 500;
    text-transform: uppercase;
    font-size: .9em;
    letter-spacing: 2px;
    text-align: center;
  }
  a {
    display: flex;
  }
 
  @media ${device.medium} {
    flex-basis: 50%;
    padding: 40px 5px;
  }

  @media ${device.small} {
    padding: 25px 15px;
  }
`;

const IconInline = styled(SVG)`
  width: 75px;
  height: auto;
  margin: 0 auto;

  path, g {
    fill: rgba(${props => props.r},${props => props.g},${props => props.b},${props => props.a});
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const ServiceListItem = ({ title, slug, iconRaw, color }) => {
  return (
    <ServiceCard >
      <div className="iconContainer">
      </div>
      <Link to={`/${slug}`}> 
        <span>{title}</span>
      </Link>
     
    </ServiceCard>
  )
}